import {
  computed,
  getCurrentInstance,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
} from "vue";
import { emitter } from "@/utils/events";
import { getChartOption } from "@/editor_core/transformer/chart";
import ComponentStyle from "@/editor_core/transformer/style/componentStyle";
import useThemeChange from "./useThemeChange";
import _ from "lodash";
import { generateChartInstance, updateInstanceSize } from "./instanceHelper";
import { generateChartOption } from "./optionHelper";
import { useStore } from "vuex";

export default {
  props: {
    id: { type: String }, // 图表ID
    type: { type: String }, // 图表类型
    componentStyle: { type: Object }, // 图表单一配置数据
    globalStyle: { type: Object }, // 图表全局配置数据
    query: { type: Object }, // 配置文件字段数据
    widget: { type: Object }, // 组件注册原型对象
  },
  setup(props) {
    const store = useStore();

    // 当前小组件对象
    const { proxy } = getCurrentInstance();
    // 主题样式分发器
    const { setChartComponentStyle } = useThemeChange(props);
    // 观察者,用于观察图表是否被缩放
    let observer = {};
    // 当前图表实例对象
    let chartInstance = {};
    // 字段的容器（例如：维度、度量）
    let queryFields = {};
    // 报表参数对象
    let chartOption = {};
    const emptyData = ref(true);
    const emptyImg = computed(() => proxy.emptyImg);

    /**
     * 设置option
     */
    const updateOption = () => {
      if (!chartInstance.instance) {
        return;
      }

      chartInstance.instance.clear();

      chartOption = getChartOption(props.globalStyle, chartOption);
      console.log("【materials gen chartOption】", chartOption);
      chartInstance.instance.setOption(chartOption);
    };
    /**
     * 组件挂载
     */
    onMounted(() => {
      // 开启事件监听
      addEvent();
    });

    /**
     * 组件卸载
     */
    onUnmounted(() => {
      // 关闭observer事件
      observer.target && observer.target.disconnect();

      // 销毁图表实例
      if (chartInstance.instance) {
        chartInstance.instance.dispose();
        delete chartInstance.instance;
      }

      // 关闭事件监听
      offEvent();
    });

    /**
     * 初始化图表
     * @param {*} data
     * @returns
     */
    const initMaterial = (chartData) => {
      // 生成报表实例
      generateChartInstance(
        proxy,
        props,
        ComponentStyle,
        chartInstance,
        observer
      );
      // 生成报表option
      generateChartOption(proxy, props, chartOption, chartData, queryFields);

      // 更新图表大小（预防页面缩放事件）
      updateInstanceSize(props, ComponentStyle, chartInstance);

      // 更新报表的option
      updateOption();
    };

    /**
     * 响应单个报表更新
     * @param {*} options
     * 
     * 示例数据：
     *  {
            "chartData": {
                "data": [
                    {
                        "省份": "四川省",
                        "到访国家数": 33044,
                        "阅读书籍量": 112342
                    },
                    {
                        "省份": "陕西省",
                        "到访国家数": 29227,
                        "阅读书籍量": 100673
                    },
                    {
                        "省份": "海南省",
                        "到访国家数": 25292,
                        "阅读书籍量": 91805
                    },
                    {
                        "省份": "山东省",
                        "到访国家数": 28990,
                        "阅读书籍量": 107883
                    },
                    ...
                ]
            }
        }
     */
    const handleChartChange = (options = {}) => {
      const {
        // 图表数据
        widgetData,
        // 无效查询字段
        unvalidQueryItem,
        // 样式设置器名称(通过修改样式场景进入才有)
        setterName,
      } = options;

      const { data } = widgetData || {};

      // 没有数据 或 存在无效查询条件 则显示封面图
      if (!data || !Array.isArray(data) || !data.length || unvalidQueryItem) {
        emptyData.value = true;
        return;
      }

      // 暂存数据
      store.dispatch("editor/updateChartData", {
        id: props.id,
        data: data,
      });

      // 关闭封面
      emptyData.value = false;

      // 更新图表
      setTimeout(() => {
        initMaterial(data);
      }, 0);

      // 如果是容器大小发生变化,则在最后重置报表大小
      setTimeout(() => {
        if (setterName === "widget_titleVisible") {
          updateInstanceSize(props, ComponentStyle, chartInstance);
        }
      });
    };

    /**
     * 响应全局主题更新
     */
    const handleThemeChange = (setterName) => {
      // 需要使用nexttick，否则不能马上拿到全局样式的最新值
      nextTick(() => {
        if (setterName === "global_styleThemeMode") {
          // 销毁旧主题实例
          if (chartInstance.instance) {
            chartInstance.instance.dispose();
            delete chartInstance.instance;
          }
          // 重新生成报表实例
          generateChartInstance(
            proxy,
            props,
            ComponentStyle,
            chartInstance,
            observer
          );
        }
        // 更新当前报表的的style值
        setChartComponentStyle(setterName);
        // 重新设置报表option
        updateOption();

        // 如果是容器大小发生变化,则在最后重置报表大小
        setTimeout(() => {
          if (setterName === "widget_titleVisible") {
            updateInstanceSize(props, ComponentStyle, chartInstance);
          }
        });
      });
    };

    /**
     * 绑定图表事件
     * @returns
     */
    const addEvent = () => {
      emitter.on(`global_theme_change`, handleThemeChange);
      props.id &&
        emitter.on(`chart_data_change_${props.id}`, handleChartChange);
    };

    /**
     * 销毁图表事件
     * @returns
     */
    const offEvent = () => {
      emitter.off(`global_theme_change`, handleThemeChange);
      props.id &&
        emitter.off(`chart_data_change_${props.id}`, handleChartChange);
    };

    const renderEmpty = () => {
      return (
        <div className="card-empty" v-show={emptyData.value}>
          <img className="card-empty_icon" src={emptyImg.value}></img>
          <div className="card-empty_text">当前图表暂无数据</div>
        </div>
      );
    };

    const renderMaterial = () => {
      const { titleBarStyle, titleStyle, titleText } =
        ComponentStyle.getTitleConfig(props.componentStyle, props.globalStyle);
      const renderTitle = () => {
        return (
          <div className="card-widget_title" style={titleBarStyle}>
            <div className="card-widget_title_text" style={titleStyle}>
              {titleText}
            </div>
          </div>
        );
      };

      const { contentStyle } = ComponentStyle.getContentStyle(
        props.componentStyle
      );
      const renderContent = () => {
        return (
          <div className="card-widget_chart no-draggable" style={contentStyle}>
            <div style={{ width: "100%", height: "100%" }} id={props.id}></div>
          </div>
        );
      };

      return (
        <div className="card-widget">
          {renderTitle()}
          {renderContent()}
        </div>
      );
    };

    return () => {
      const { cardStyle } = ComponentStyle.getCardStyle(
        props.componentStyle,
        props.globalStyle
      );
      return (
        <div className="card-box" style={cardStyle}>
          {renderEmpty()}
          {renderMaterial()}
        </div>
      );
    };
  },
};
