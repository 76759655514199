import { createRouter, createWebHashHistory } from "vue-router";
import cookie from "js-cookie";
import store from "@/store";
import { getUser } from "@/api/user";
import { ElMessage } from "element-plus";
import { getUrlParam } from "@/utils";

const BaseRoutes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/index"),
  },
  {
    path: "/editor",
    name: "editor",
    meta: { requiresAuth: true },
    component: () => import("@/views/editor/index"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: { requiresAuth: true },
    component: () => import("@/views/dashboard/index"),
  },
  {
    path: "/example",
    name: "example",
    component: () => import("@/views/example/index"),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes: BaseRoutes,
});

router.beforeEach(async (to, from, next) => {
  const token = cookie.get("token");
  const mode = getUrlParam("mode");
  if (to.meta.requiresAuth && mode !== "view" && !token) {
    ElMessage.warning("请登陆后再访问");
    return next("/login");
  }
  if (to.path === "/login" && token) {
    return next("/dashboard");
  }
  const user = store.state.user;
  if (!user && to.meta.requiresAuth) {
    const user = await getUser();
    store.dispatch("setUser", user);
  }
  next();
});

export default router;
