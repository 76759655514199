import {
  SET_EDITOR_DATA,
  UPDATE_CHART_DATA,
  SET_DATA_SET,
  SET_CURR_TDASHBOARD,
  SET_AUTOREFRE_TIME
} from "./mutation-types"
export default {
  [SET_EDITOR_DATA](state, data) {
    state.editorData = data
  },
  [UPDATE_CHART_DATA](state, payload) {
    state.chartDataMap[payload.id] = payload.data
  },
  [SET_DATA_SET](state, payload) {
    state.dataset = payload
  },
  [SET_CURR_TDASHBOARD](state, payload) {
    state.currDashboard = payload
  },
  [SET_AUTOREFRE_TIME](state, payload) {
    state.intervalDuration = payload
  },
}
