import {
  SET_EDITOR_DATA,
  UPDATE_CHART_DATA,
  SET_DATA_SET,
  SET_CURR_TDASHBOARD,
  SET_AUTOREFRE_TIME
} from "./mutation-types"
import { getDatasetList } from "@/api/dataset"

export default {
  // 设置编辑器数据
  setEditorData({ commit }, data) {
    commit(SET_EDITOR_DATA, data)
  },
  // 更新图表数据
  updateChartData({ commit }, data) {
    commit(UPDATE_CHART_DATA, data)
  },
  // 设置数据集
  async setDataSet({ commit }) {
    const result = await getDatasetList()
    commit(SET_DATA_SET, result)
  },
  setCurrDashboard({ commit }, data) {
    commit(SET_CURR_TDASHBOARD, data)
  },
  setAutoRefreTime({ commit }, data) {
    commit(SET_AUTOREFRE_TIME, data)
  },
}
