/**
 * 生成报表实例
 * @param {*} proxy
 * @param {*} props
 * @param {*} ComponentStyle
 * @param {*} chartInstance
 * @param {*} observer
 * @returns
 */
export const generateChartInstance = (
  proxy,
  props,
  ComponentStyle,
  chartInstance,
  observer
) => {
  if (chartInstance.instance) {
    return;
  }
  // 获取图表容器
  const chartDom = document.getElementById(props.id);
  if (!chartDom) {
    console.error("[generateChartInstance] get echarts dom error");
    return;
  }
  // 图表初始化
  chartInstance.instance = proxy.$echarts.init(
    chartDom,
    props.globalStyle.global_styleThemeMode
  );
  if (!chartInstance.instance) {
    console.error("[generateChartInstance] proxy.$echarts.init error");
    return;
  }
  // 事件绑定
  addResizeEvent(props, ComponentStyle, chartInstance, observer);
};

/**
 * 报表实例添加事件（例如：resize）
 * @param {*} props
 * @param {*} ComponentStyle
 * @param {*} chartInstance
 * @param {*} observer
 */
const addResizeEvent = (props, ComponentStyle, chartInstance, observer) => {
  const resizeObserver = window.ResizeObserver;
  // 创建resizeObserver对象
  observer.target = new resizeObserver((domList) => {
    // 目标触发resize事件
    if (domList[0]) {
      updateInstanceSize(props, ComponentStyle, chartInstance);
    }
  });
  // 设置观察者
  observer.target.observe(document.getElementById(`grid-item__${props.id}`));
};

/**
 * 更新图表大小
 * @param {*} props
 * @param {*} ComponentStyle
 * @param {*} chartInstance
 */
export const updateInstanceSize = (props, ComponentStyle, chartInstance) => {
  // 重新获取报表容器的大小
  const rect = ComponentStyle.getChartClientRect(props.id);
  if (!rect) {
    return;
  }
  if (props.componentStyle.widget_titleVisible) {
    rect.height = rect.height - 35;
  }
  // 存在实例
  if (chartInstance.instance) {
    chartInstance.instance.resize({ ...rect, animation: { duration: 500 } });
  }
};
