import http from "./core/http";

export const userLogin = (params) => {
  return http.post("/user/login", params);
};

export const userRegister = (params) => {
  return http.post("/user/register", params);
};

export const getUser = () => {
  return http.get("/user/getUser");
};
export const logout = () => {
  return http.get("/user/logout");
};