export const baseConfig = {
  tooltip: {
    show: true,
    trigger: "axis", // 触发类型 - 坐标轴触发
    axisPointer: { type: "shadow" }, // 指示器类型 - 阴影
  },
  legend: {
    top: 10,
  },
  grid: {
    containLabel: true,
    top: 50,
    bottom: 10,
    left: 20,
    right: 50,
  },
  xAxis: {
    axisLabel: {
      rotate: 45,
      width: 50, // 文本显示宽度
      overflow: "truncate", // 文字超出宽度截断
    },
  },
}

export const seriesConfig = {}
