import { SET_VIEW_MODE, SET_USER } from "./mutation-types";

export default {
  // 设置视图模式
  setViewMode({ commit }, data) {
    commit(SET_VIEW_MODE, data);
  },
  // 设置视图模式
  setUser({ commit }, data) {
    commit(SET_USER, data);
  },
};
