import { KeepAlive, defineComponent } from "vue"

export default defineComponent({
  setup() {
    return () => {
      return (
        <router-view
          v-slots={{
            default: (scope) => <KeepAlive>{scope.Component}</KeepAlive>,
          }}
        ></router-view>
      )
    }
  },
})
