import { computed, defineComponent } from "vue";
import "./iconMultiple.scss";

export default defineComponent({
  name: "icon-multiple",
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    setter: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["update:value", "change"],
  setup(props, ctx) {
    const valueData = computed({
      get: () => {
        return props.value
      },
      set: (val) => {
        ctx.emit("update:value", val);
      },
    });

    // 自定义表单组件设值
    const setCustomFormItemValue = (props, value) => {
      const { multiple, name } = props;
      let temp = {};
      if (multiple) {
        const v = valueData.value[name];
        const index = v.indexOf(value);
        if (index > -1) {
          v.splice(index, 1);
        } else {
          v.push(value);
        }
      } else {
        temp = { [name]: [value] };
      }
      valueData.value = { ...valueData.value, ...temp };
      ctx.emit("change");
    };

    return () => {
      return (
        <div className="icon-multiple">
            {props.setter.options.map((option) => {
              let className = ["node"];
              className.push(option.icon);
              if (valueData.value[props.setter.name].indexOf(option.value) > -1) {
                className.push("active");
              }
              return (
                <div
                  onClick={() => setCustomFormItemValue(props.setter, option.icon)}
                  className={className.join(" ")}
                ></div>
              );
            })}
          </div>
      );
    };
  },
});
