import mitt from "mitt";

export const events = mitt();

const maxRetryCount = 30;
const timeout = 100;
class EventEmitter {
  constructor() {
    // 存储事件名称与对应的处理函数
    this.events = {};
    // 存储事件的重试次数
    this.retryCount = {};
  }

  // 订阅事件
  on(eventName, callback) {
    // 如果事件不存在，则初始化为一个空数组
    if (!this.events[eventName]) {
      this.events[eventName] = [];
    }
    // 将处理函数添加到对应事件的处理函数数组中
    this.events[eventName].push(callback);
  }

  // 发布事件
  emit(eventName, ...args) {
    // 如果事件存在，则依次调用对应的处理函数
    if (this.events[eventName]) {
      // 事件成功被处理，停止重试并清零重试次数
      delete this.retryCount[eventName];
      this.events[eventName].forEach((callback) => {
        callback(...args);
      });
    } else {
      // 如果事件不存在，则自动调用重试
      this.retryUnsubscribedEvent(eventName, args);
    }
  }

  // 重试未处理的事件
  retryUnsubscribedEvent(eventName, args) {
    // 如果事件未被重试过，则初始化重试次数为0
    if (!this.retryCount[eventName]) {
      this.retryCount[eventName] = 0;
    }

    // 如果重试次数小于3次，则继续重试
    if (this.retryCount[eventName] < maxRetryCount) {
      // 增加重试次数
      this.retryCount[eventName]++;

      // 在一定延迟后进行重试
      setTimeout(() => {
        this.emit(eventName, ...args);
      }, timeout); // 延迟时间为1秒，你可以根据实际情况进行调整
    } else {
      // 重试次数达到3次后，清除重试计数
      delete this.retryCount[eventName];
    }
  }

  // 取消订阅事件
  off(eventName, callback) {
    // 如果事件存在，则在对应事件的处理函数数组中删除指定的处理函数
    if (this.events[eventName]) {
      this.events[eventName] = this.events[eventName].filter(
        (cb) => cb !== callback
      );
    }
  }
}

export const emitter = new EventEmitter();
