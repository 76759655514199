import * as echarts from "echarts";
import customDarkTheme from "./theme/custom_dark.project.json";
import customLightTheme from "./theme/custom_light.project.json";
import customFreshTheme from "./theme/custom_fresh.project.json";
import customGreyTheme from "./theme/custom_grey.project.json";
import customGraffitiTheme from "./theme/custom_graffiti.project.json";
import customMacaronTheme from "./theme/custom_macaron.project.json";
import customBlueTheme from "./theme/custom_blue.project.json";
import customDarkblueTheme from "./theme/custom_darkblue.project.json";
import customWineTheme from "./theme/custom_wine.project.json";
echarts.registerTheme("dark", customDarkTheme);
echarts.registerTheme("light", customLightTheme);
echarts.registerTheme("fresh", customFreshTheme);
echarts.registerTheme("grey", customGreyTheme);
echarts.registerTheme("graffiti", customGraffitiTheme);
echarts.registerTheme("macaron", customMacaronTheme);
echarts.registerTheme("blue", customBlueTheme);
echarts.registerTheme("darkblue", customDarkblueTheme);
echarts.registerTheme("wine", customWineTheme);

import EchartLine from "./line/index.jsx";
import EchartBar from "./bar/index.jsx";
import EchartPie from "./pie/index.jsx";
import EchartStrip from "./strip/index.jsx";
import EchartArea from "./area/index.jsx";

const components = [EchartLine, EchartBar, EchartPie, EchartStrip, EchartArea];

export default function initEcharts(app) {
  app.config.globalProperties.$echarts = echarts
  components.forEach((component) => {
    app.component(component.name, component);
  });
}
