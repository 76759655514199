import EmptyImg from "@/assets/img/card/empty/heat.png";

export default {
  data() {
    return {
      defaultOption: {},
      emptyImg: EmptyImg,
    };
  },
  methods: {
    generateLayerMap(mapInstance, mapData, currentMeasure) {
      return new Promise((resolve) => {
        if (mapInstance.heatmap) {
          // 隐藏热力图
          mapInstance.heatmap.hide();
          // 移除热力图
          mapInstance.heatmap.setMap(null);
        }
        mapInstance.container.plugin(["AMap.HeatMap"], function () {
          const heatmap = new mapInstance.instance.HeatMap(
            mapInstance.container,
            {
              radius: 25, // 半径
              opacity: [0, 0.8], // 透明度范围
            }
          );
          const list = mapData
            .filter((item) => currentMeasure.value.includes(item.resourceName))
            .map((item) => {
              item.lng = item.lnglat[0];
              item.lat = item.lnglat[1];
              item.count = 1;
              return item;
            });
          heatmap.setDataSet({
            data: list,
          });
          mapInstance.heatmap = heatmap;
          // 显示热力图
          mapInstance.heatmap.show();
          resolve();
        });
      });
    },
  },
};
