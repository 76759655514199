import EmptyImg from "@/assets/img/card/empty/area.png"
import { baseConfig, seriesConfig } from "@/config/chartStyleConfig"
import _ from "lodash"

export default {
  data() {
    return {
      defaultOption: _.cloneDeep(baseConfig),
      emptyImg: EmptyImg,
    }
  },
  methods: {
    genCustomOption(chartOption, dimensionData, seriesData) {
      // 系列设值
      chartOption.series = seriesData.map((item) => {
        item.type = "line"
        item.areaStyle = { opacity: 0.5 } // 区域填充样式。设置后显示成区域面积图
        return { ...seriesConfig, ...item }
      })

      // xAxis设值
      chartOption.xAxis = {
        type: "category",
        data: dimensionData,
      }

      // yAxis设值
      chartOption.yAxis = {
        type: "value",
      }

      // 其余属性合并
      for (const k in this.defaultOption) {
        const opt = this.defaultOption[k]
        chartOption[k] = { ...opt, ...chartOption[k] }
      }
    },
  },
}
