import { map_web_key } from "@/config";

/**
 * 生成地图实例
 * @param {*} proxy
 * @param {*} props
 * @param {*} mapInstance
 * @returns
 */
export const generateMapInstance = async (proxy, props, mapInstance) => {
  if (mapInstance.instance) {
    return;
  }
  // 获取图表容器
  const chartDom = document.getElementById(props.id);
  if (!chartDom) {
    console.error("[generateMapInstance] get echarts dom error");
    return;
  }
  await new Promise((resolve, reject) => {
    proxy.$map
      .load({
        key: map_web_key, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
      .then((instance) => {
        mapInstance.instance = instance;
        mapInstance.container = new mapInstance.instance.Map(props.id, {
          mapStyle: `amap://styles/${props.globalStyle.global_styleThemeMode}`,
          zoom: 4, // 初始化地图级别
          // zoomEnable: false,
          center: [104, 35], // 初始化地图中心点位置
        });
        resolve();
      })
      .catch(() => {
        console.error("[generateMapInstance] proxy.$map.load error");
        reject();
      });
  });

  addControl(mapInstance);
};

/**
 * 添加控件
 * @param {*} mapInstance
 */
const addControl = (mapInstance) => {
  mapInstance.container.plugin(["AMap.ToolBar", "AMap.Scale"], function () {
    //添加工具条控件，工具条控件集成了缩放、平移、定位等功能按钮在内的组合控件
    mapInstance.container.addControl(new mapInstance.instance.ToolBar());
    //添加比例尺控件，展示地图在当前层级和纬度下的比例尺
    // mapInstance.container.addControl(new mapInstance.instance.Scale());
  });
};
