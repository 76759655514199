/**
 * 生成图表配置
 * @returns
 */
export const generateChartOption = (props, queryFields, currentMeasure) => {
  // 生成维度、度量数据备用 - 装入queryFields
  generateQueryFields(props, queryFields, currentMeasure);
};

/**
 * 提取查询字段（维度、度量 对应的名称）
 * @param {*} props
 * @param {*} queryFields
 */
const generateQueryFields = (props, queryFields, currentMeasure) => {
  // 初始化数据容器
  queryFields.dimension = [];
  queryFields.measure = [];

  // 遍历该配置
  props.query.area.forEach((item) => {
    /**
     * 
     * item格式示例（queryName为组件字段，其他为查询器合并入的字段）
     * {
          "label": "类别轴/维度",
          "value": [
              {
                  "name": "省份",
                  "value": "address"
              }
          ],
          "rule": {
              "type": "dimension",
              "required": true
          },
          "queryName": "area_category"
       }
      */
    // 当前领域是否配置type（对应：dimension、measure等）
    if (item.rule && item.rule.type) {
      // 类目轴 或 值轴
      if (["area_category", "area_value"].indexOf(item.queryName) > -1) {
        // 遍历用户选择的的字段
        item.value.forEach((valueItem) => {
          // 把 字段 放到对应领域的容器中去
          queryFields[item.rule.type].push(valueItem.name);
        });
      }
    }
  });
  currentMeasure.value = props.widget.multipleData
    ? queryFields.dimension
    : queryFields.dimension.slice(0, 1);
};
