export const SET_EDITOR_DATA = 'SET_EDITOR_DATA'
export const UPDATE_CHART_DATA = 'UPDATE_CHART_DATA'
export const SET_DATA_SET = 'SET_DATA_SET'
export const SET_CURR_TDASHBOARD = 'SET_CURR_TDASHBOARD'
export const SET_AUTOREFRE_TIME = 'SET_AUTOREFRE_TIME'






