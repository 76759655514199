export default {
  getEditorData: (state) => {
    return state.editorData
  },
  getChartData: (state) => (id) => {
    return state.chartDataMap[id]
  },
  getDataset: (state) => {
    return state.dataset
  }
}