import { computed, defineComponent, ref } from "vue";
import { ElIcon, ElImage, ElPopover, ElUpload } from "element-plus";
import { Picture, UploadFilled } from "@element-plus/icons-vue";
import { image_material, uploadFileAction } from "@/config";
import "./uploadImage.scss";

export default defineComponent({
  name: "upload-image",
  props: {
    value: {
      type: String,
      default: () => {},
    },
    materialType: {
      type: String,
    },
  },
  emits: ["update:value", "change"],
  setup(props, ctx) {
    const valueData = computed({
      get: () => {
        return props.value;
      },
      set: (val) => {
        ctx.emit("update:value", val);
      },
    });
    const visible = ref(false);
    const activeTab = ref("0");
    const uploadSuccess = (e) => {
      const {
        result: { url },
      } = e;
      visible.value = false;
      valueData.value = url;
      ctx.emit("change", url);
    };
    const selectImage = (url) => {
      valueData.value = url;
      visible.value = false;
      ctx.emit("change", url);
    };
    const removeUploadFile = () => {
      valueData.value = "";
      visible.value = false;
      ctx.emit("change", "");
    };
    const materialList = computed(() => {
      return image_material[props.materialType];
    });
    return () => {
      return (
        <ElPopover
          width="400"
          trigger="click"
          placement="top"
          v-model:visible={visible.value}
        >
          {{
            default: () => {
              return (
                <div className="upload-image">
                  <ElTabs v-model={activeTab.value}>
                    <ElTabPane
                      label="使用素材"
                      name="0"
                      className="tab_material"
                    >
                      {materialList.value.map((item) => {
                        return (
                          <ElImage
                            onClick={() => selectImage(item)}
                            class={valueData.value == item ? 'select' : ''}
                            src={item}
                          ></ElImage>
                        );
                      })}
                    </ElTabPane>
                    <ElTabPane
                      label="自定义上传"
                      name="1"
                      className="tab_custom"
                    >
                      <ElUpload
                        class="upload-image"
                        action={uploadFileAction}
                        on-success={uploadSuccess}
                        show-file-list={false}
                      >
                        <el-button type="primary" plain>
                          <ElIcon>
                            <UploadFilled />
                          </ElIcon>
                          <span>
                            {valueData.value ? "重新上传" : "上传本地图片"}
                          </span>
                        </el-button>
                      </ElUpload>
                      <div className="upload-desc">
                        只支持jpg,jpeg,png,gif,svg格式,最大2M
                      </div>
                    </ElTabPane>
                  </ElTabs>
                  <div className="bottom">
                    <span onClick={removeUploadFile}>清空图片</span>
                  </div>
                </div>
              );
            },
            reference: () => {
              return (
                <div class="upload-btn">
                  {valueData.value ? (
                    <ElImage src={valueData.value}></ElImage>
                  ) : (
                    <ElIcon>
                      <Picture />
                    </ElIcon>
                  )}
                </div>
              );
            },
          }}
        </ElPopover>
      );
    };
  },
});

{
  /* {valueData.value ? renderImage() : renderUpload()} */
}
