import EmptyImg from "@/assets/img/card/empty/pitting.png";

export default {
  data() {
    return {
      defaultOption: {},
      emptyImg: EmptyImg,
    };
  },
  methods: {
    transformLngLat(val) {
      return val.map((val) => {
        const randomValue = Math.random() * 6 - 3;
        const roundedRandomValue = Number(randomValue.toFixed(6));
        return val + roundedRandomValue;
      });
    },
    getStyle(mapInstance) {
      return [
        "65c8cc719f345e8d036ef0d0.png",
        "65c8cc719f345e8d036ef148.png",
        "65c8cc719f345e8d036ef1c5.png",
        "65c8cc719f345e8d036ef273.png",
        "65c8cc739f345e8d036ef934.png",
        "65c8cc789f345e8d036f0f07.png",
      ].map((item) => {
        return {
          url: `https://pic.imgdb.cn/item/${item}.png`,
          anchor: new mapInstance.instance.Pixel(0, 0), // 图标显示位置偏移量，基准点为图标左上角
          size: new mapInstance.instance.Size(20, 20), // 图标的尺寸
        };
      });
    },
    generateLayerMap(mapInstance, mapData, currentMeasure, queryFields) {
      return new Promise((resolve) => {
        mapInstance.container.clearMap();
        const dimension = queryFields.dimension;
        const list = mapData
          .filter((item) => currentMeasure.value.includes(item.resourceName))
          .map((item) => {
            const index = dimension.findIndex((node) => {
              return node == item.resourceName;
            });
            item.style = index;
            return item;
          });
        const MassMarks = new mapInstance.instance.MassMarks(list, {
          opacity: 0.8,
          cursor: "pointer",
          zIndex: 100,
          style: this.getStyle(mapInstance),
        });
        MassMarks.setMap(mapInstance.container);

        MassMarks.on("mouseover", function (e) {
          if (mapInstance.marker) {
            mapInstance.marker.setMap(null);
            mapInstance.marker = null;
          }
          const marker = new mapInstance.instance.Marker({
            content: " ",
            anchor: "center",
            zIndex: 200,
            map: mapInstance.container,
          });
          marker.setPosition(e.data.lnglat);
          marker.setLabel({ content: e.data.address });
          mapInstance.marker = marker;
        });

        resolve();
      });
    },
  },
};
