export const getChartOption = (globalStyle, options = {}) => {
  const chartOption = { ...options }
  // 主题设置
  setGlobalTheme(chartOption, globalStyle)
  return chartOption
}

/**
 * 主题设置
 * @param {*} chartOption
 * @param {*} globalStyle
 */
const setGlobalTheme = (chartOption, globalStyle) => {
  const { global_styleColorScheme, global_styleFontFamily } = globalStyle
  chartOption.color = global_styleColorScheme.value // 报表色系
  chartOption.textStyle = { // 报表字体
    fontFamily: global_styleFontFamily,
  }
}