import { ElIcon } from "element-plus";
import { Histogram, SetUp, DataLine, Tickets } from "@element-plus/icons-vue";

// 用于加载地图
export const map_web_key = "511632af9f6838d55b8993def0e5e915";

// 用于请求地图api
export const map_web_service_key = "9144e396e117d4fb60b3a14a13a4dbc1";

// 上传文件地址
export const uploadFileAction =
  process.env.NODE_ENV === "production"
    ? "//api.cccccl.com/api/v1/file/upload"
    : "//127.0.0.1:7001/api/v1/file/upload";

// 素材图片
export const image_material = {
  topBg: [
    "https://pic.imgdb.cn/item/65c47a739f345e8d030c838e.webp",
    "https://pic.imgdb.cn/item/65c47adc9f345e8d030d7859.webp",
    "https://pic.imgdb.cn/item/65c47adc9f345e8d030d79a1.webp",
    "https://pic.imgdb.cn/item/65c47adc9f345e8d030d7a66.webp",
  ],
  bottomBg: [
    "https://pic.imgdb.cn/item/65c47b2f9f345e8d030e5232.webp",
    "https://pic.imgdb.cn/item/65c47b2f9f345e8d030e532d.webp",
    "https://pic.imgdb.cn/item/65c47b2f9f345e8d030e53f0.webp",
    "https://pic.imgdb.cn/item/65c47b309f345e8d030e54aa.webp",
  ],
  globalBg: [
    "https://pic.imgdb.cn/item/65c47b629f345e8d030ed997.webp",
    "https://pic.imgdb.cn/item/65c47b609f345e8d030ed4ec.webp",
    "https://pic.imgdb.cn/item/65c47b609f345e8d030ed630.webp",
    "https://pic.imgdb.cn/item/65c47b619f345e8d030ed7fd.webp",
  ],
};

// 颜色组选择
export const colorSystemList = [
  {
    label: "默认",
    options: [
      {
        label: "默认",
        value: [
          "#7b7dff",
          "#ffcf00",
          "#61a0a8",
          "#d48265",
          "#91c7ae",
          "#749f83",
        ],
      },
    ],
  },
  {
    label: "八爪鱼",
    options: [
      {
        label: "八爪鱼1",
        value: [
          "#085574",
          "#017D9F",
          "#F49C7A",
          "#FFE7BC",
          "#819E42",
          "#D57461",
        ],
      },
      {
        label: "八爪鱼2",
        value: [
          "#30392A",
          "#856947",
          "#7D8860",
          "#D9CDB4",
          "#C9983D",
          "#4D5F39",
        ],
      },
      {
        label: "八爪鱼3",
        value: [
          "#022D58",
          "#285B99",
          "#D98B21",
          "#DBDFEE",
          "#A28E4E",
          "#794D28",
        ],
      },
      {
        label: "八爪鱼4",
        value: [
          "#444635",
          "#4970B7",
          "#E37366",
          "#FBB07A",
          "#EFCED3",
          "#7B5633",
        ],
      },
      {
        label: "八爪鱼5",
        value: [
          "#193A21",
          "#E27443",
          "#6E923D",
          "#F9CA9B",
          "#F4C330",
          "#285A2C",
        ],
      },
      {
        label: "八爪鱼6",
        value: [
          "#104770",
          "#1F6EAC",
          "#F9B020",
          "#E7DFD7",
          "#9BAF5D",
          "#A24F37",
        ],
      },
    ],
  },
  {
    label: "分析属性",
    options: [
      {
        label: "智能",
        value: [
          "#2E74FF",
          "#58CAF4",
          "#867EEC",
          "#FCBC3D",
          "#45D0B5",
          "#5B7BBA",
        ],
      },
      {
        label: "鲜明",
        value: [
          "#2E88FF",
          "#FE8433",
          "#1FD3CD",
          "#6D6DF2",
          "#4CA2DC",
          "#FFB70A",
        ],
      },
      {
        label: "舒适",
        value: [
          "#40BFBF",
          "#3F5A8F",
          "#998FCC",
          "#FDAC33",
          "#538EB6",
          "#72BCFA",
        ],
      },
      {
        label: "简约",
        value: [
          "#4786FF",
          "#14C7C7",
          "#FFB266",
          "#8B8AE6",
          "#49B0F5",
          "#FFA299",
        ],
      },
    ],
  },
  {
    label: "场景属性",
    options: [
      {
        label: "色盲无障碍",
        value: [
          "#527ACC",
          "#FF9F40",
          "#3E4D99",
          "#A3CCE9",
          "#636E82",
          "#E6710F",
        ],
      },
      {
        label: "数据之美",
        value: [
          "#3D71FF",
          "#40D4C6",
          "#7961F4",
          "#F69173",
          "#38AE9A",
          "#A66BE5",
        ],
      },
      {
        label: "节日",
        value: [
          "#FF7C1F",
          "#FFB521",
          "#7D8FEA",
          "#67ADF2",
          "#78DB91",
          "#37AF91",
        ],
      },
      {
        label: "商务",
        value: [
          "#697CD9",
          "#45BCFF",
          "#52CCB8",
          "#74D1DA",
          "#5288D3",
          "#F7CE36",
        ],
      },
      {
        label: "时尚",
        value: [
          "#8A6EFA",
          "#48BAFF",
          "#F17FDE",
          "#F3B7FF",
          "#58CE93",
          "#A8E79A",
        ],
      },
    ],
  },
  {
    label: "行业属性",
    options: [
      {
        label: "制造",
        value: [
          "#0F8299",
          "#12B3B3",
          "#6CD99F",
          "#ACE673",
          "#FFDB4D",
          "#4C993D",
        ],
      },
      {
        label: "理性蓝",
        value: [
          "#4476DA",
          "#49D6C1",
          "#35A2D8",
          "#5ED9E6",
          "#58B3B3",
          "#4E69B2",
        ],
      },
      {
        label: "热情红",
        value: [
          "#E86675",
          "#FF9F7A",
          "#CC7A9D",
          "#D999D9",
          "#BC6ACC",
          "#955989",
        ],
      },
      {
        label: "金融",
        value: [
          "#FDB937",
          "#4CB890",
          "#74AFF3",
          "#4F77C5",
          "#FF8F43",
          "#BDABD3",
        ],
      },
      {
        label: "快消",
        value: [
          "#FF735A",
          "#5BCCB6",
          "#4782B3",
          "#EB7BC0",
          "#FFAA33",
          "#FED840",
        ],
      },
    ],
  },
  {
    label: "艺术风格",
    options: [
      {
        label: "星月夜",
        value: [
          "#5465C6",
          "#4E90E5",
          "#FCD229",
          "#E98800",
          "#42588F",
          "#C09AD0",
        ],
      },
      {
        label: "日出印象",
        value: [
          "#C58C6D",
          "#D95E52",
          "#EEBA60",
          "#758D99",
          "#3A5C67",
          "#A2AB90",
        ],
      },
      {
        label: "经典色盘",
        value: [
          "#2D84DD",
          "#34A6DB",
          "#17CEDD",
          "#3BC397",
          "#84D377",
          "#B7DB70",
        ],
      },
      {
        label: "高级灰调",
        value: [
          "#5A638A",
          "#93B8D3",
          "#5262AD",
          "#85A7E8",
          "#A2A2F7",
          "#54759A",
        ],
      },
      {
        label: "灵动",
        value: [
          "#7F63DA",
          "#FFC237",
          "#49B4B6",
          "#76D0E6",
          "#F9945F",
          "#9E94DC",
        ],
      },
      {
        label: "国风复古",
        value: [
          "#276567",
          "#F65E40",
          "#F7BE27",
          "#BD7E5B",
          "#F18366",
          "#7B6F74",
        ],
      },
    ],
  },
];

// 主题列表
export const themeList = [
  {
    theme: "dark",
    label: "幻影黑",
    url: "https://pic.imgdb.cn/item/65c7857c9f345e8d0332ca03.png",
  },
  {
    theme: "light",
    label: "月光银",
    url: "https://pic.imgdb.cn/item/65c785b29f345e8d0333afd5.png",
  },
  {
    theme: "fresh",
    label: "草色青",
    url: "https://pic.imgdb.cn/item/65c7857d9f345e8d0332ce47.png",
  },
  {
    theme: "grey",
    label: "雅士灰",
    url: "https://pic.imgdb.cn/item/65c785b29f345e8d0333ada4.png",
  },
  {
    theme: "graffiti",
    label: "涂鸦",
    url: "https://pic.imgdb.cn/item/65c7857e9f345e8d0332d057.png",
  },
  {
    theme: "macaron",
    label: "马卡龙",
    url: "https://pic.imgdb.cn/item/65c785b39f345e8d0333b15f.png",
  },
  {
    theme: "blue",
    label: "靛青蓝",
    url: "https://pic.imgdb.cn/item/65c7857c9f345e8d0332c7b7.png",
  },
  {
    theme: "darkblue",
    label: "极夜蓝",
    url: "https://pic.imgdb.cn/item/65c7857d9f345e8d0332cba9.png",
  },
  {
    theme: "wine",
    label: "酱仔",
    url: "https://pic.imgdb.cn/item/65c785b39f345e8d0333b37b.png",
  },
];

export const menuList = [
  {
    label: "数据分析",
    value: "0",
    subMenu: [
      {
        label: "仪表盘",
        value: "0-0",
        icon: (
          <ElIcon>
            <DataLine />
          </ElIcon>
        ),
        isDisabled: false,
      },
      {
        label: "大屏",
        value: "0-1",
        icon: (
          <ElIcon>
            <Histogram />
          </ElIcon>
        ),
        isDisabled: true,
      },
    ],
  },
  {
    label: "数据构建",
    value: "1",
    subMenu: [
      {
        label: "数据集",
        value: "1-0",
        icon: (
          <ElIcon>
            <SetUp />
          </ElIcon>
        ),
        isDisabled: true,
      },
      {
        label: "数据源",
        value: "1-1",
        icon: (
          <ElIcon>
            <Tickets />
          </ElIcon>
        ),
        isDisabled: true,
      },
    ],
  },
];
